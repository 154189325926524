
import { Component, Watch } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import { Venue } from '@/interfaces/models/Venue';
import { Table as TableModel } from '@/interfaces/models/Table';
import { Payment as PaymentModel } from '@/interfaces/models/Payment';
import Notification from '@/mixins/Notification';
import TableTable from '@/components/table/TableTable.vue';
import VEmptyState from '@/components/shared/VEmptyState.vue';
import { mixins } from 'vue-class-component';
import Filter from '@/interfaces/api/Filter';
import PaymentTable from '@/components/payment/PaymentTable.vue';
import { Order } from '@/interfaces/models/Order';
import OrderServiceApi from '@/api/http/OrderServiceApi';
import VRangePicker from '@/components/shared/form/VRangePicker.vue';

const venue = namespace('venue');
const payment = namespace('payment');

@Component({
  components: { PaymentTable, VEmptyState, TableTable, VWrapper, VRangePicker },
})
export default class Payment extends mixins(Notification) {
  @venue.State('active') public venue!: Venue;

  @payment.State('items') public payments!: PaymentModel[];
  @payment.State((state) => state.pagination.total) public total!: number;
  @payment.Action('fetch') public getPayments!: any;
  @payment.State('filter') public filter!: Filter;
  @payment.Action('setFilter') public setFilter!: (filter: Filter) => void;
  @payment.Action('refund') public refund!: any;

  public search: string = '';

  public $refs!: {
    table: InstanceType<typeof PaymentTable> & { getSelected: () => TableModel[] };
  };

  public orderMap: Map<string, Order> = new Map<string, Order>();

  public orders: Order[] = [];

  public created() {
    if (!this.venue) {
      this.$startLoading('venue');
    }
  }

  public async doRefund(data: { order: string; articles: string[] }) {
    await this.refund(data);
    await this.getPayments({ page: 1 });
  }

  public async getOrders(item: PaymentModel) {
    this.$startLoading('order');
    const api: OrderServiceApi = new OrderServiceApi();
    for (const order of item.orders) {
      if (!this.orderMap.has((order as Partial<Order>)._id as string)) {
        this.orderMap.set(
          (order as Partial<Order>)._id as string,
          (await api.show({ id: (order as Partial<Order>)._id as string })).data,
        );
      }
    }
    this.orders = Array.from(this.orderMap.values());
    this.$stopLoading('order');
  }

  public mounted() {
    if (!this.venue) {
      this.$startLoading('venue');
    }
    if (this.venue) {
      this.$startLoading('payment');
      this.setFilter({ ...this.filter, venue: this.venue._id });
      this.getPayments({ page: 1 });
      this.$stopLoading('payment');
    }
  }

  @Watch('search')
  public onSearch() {
    if (this.venue && this.venue._id && this.search.length > 0) {
      this.$startLoading('payment');
      this.setFilter({ ...this.filter, venue: this.venue._id, code: this.search });
      this.getPayments({ page: 1 });
      this.$stopLoading('payment');
      return;
    }
    if (this.search.length === 0) {
      this.$startLoading('payment');
      const filter: Filter = { ...this.filter, venue: this.venue._id };
      delete filter.code;
      this.setFilter(filter);
      this.getPayments({ page: 1 });
      this.$stopLoading('payment');
    }
  }

  public onChangeRange(data: any): void {
    this.$startLoading('payment');
    this.setFilter({ ...this.filter, ...data });
    this.getPayments({ page: 1 });
    this.$stopLoading('payment');
  }

  @Watch('venue')
  public onVenueChange() {
    if (this.venue && this.venue._id) {
      this.$startLoading('payment');
      this.setFilter({ ...this.filter, venue: this.venue._id });
      this.getPayments({ page: 1 });
      this.$stopLoading('payment');
    }
  }
}
