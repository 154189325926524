
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { ColumnHeader } from '@/interfaces/components/ColumnHeader';
import VTable from '@/components/shared/table/VTable.vue';
import { Table } from '@/interfaces/models/Table';
import headers from './headers';
import OrderList from '@/components/payment/OrderList.vue';
import { Page } from '@/interfaces/api/Page';
import { Payment } from '@/interfaces/models/Payment';
import { Order } from '@/interfaces/models/Order';
import { PaymentMethod } from '@/enums/PaymentMethod';
import { ValidationObserver } from 'vee-validate';
import { Permission } from '@/enums/Permission';

@Component({
  components: { OrderList, VTable },
})
export default class PaymentTable extends Vue {
  get pageCount() {
    return Math.ceil(this.total / 10);
  }

  get tableHeaders() {
    return headers.map((h: ColumnHeader) => ({ ...h, text: this.$t(h.text) }));
  }

  @Prop({ type: Array, required: true }) public items!: Table[];
  @Prop({ type: Number, required: true }) public total!: number;
  @Prop({ type: Array, required: true }) public orders!: Order[];

  public page: number = 1;

  public expanded: string[] = [];

  public $refs!: {
    table: InstanceType<typeof VTable> & { selected: any[] };
    observer: Array<InstanceType<typeof ValidationObserver>>;
  };

  @Emit('page')
  @Watch('page')
  public onPageChange(): Page {
    return { page: this.page };
  }

  public isRefundable(item: Payment): boolean {
    if (item.method === PaymentMethod.CASH && this.$can(Permission.REFUNDS_CASH)) {
      return true;
    }

    return (
      [
        PaymentMethod.CREDIT_CARD,
        PaymentMethod.PAYPAL,
        PaymentMethod.APPLE_PAY,
        PaymentMethod.GOOGLE_PAY,
        PaymentMethod.WORLDLINE_RAW,
        PaymentMethod.VR_SEPA,
      ].indexOf(item.method) > -1
    );
  }

  public isMethodWorldlineRaw(item: Payment) {
    return PaymentMethod.WORLDLINE_RAW === item.method;
  }

  public refund(data: { payment: Payment; order: string; articles: string[]; isExternal: boolean }) {
    this.$refs.observer[0].validate().then((res: boolean) => {
      if (res) {
        this.$confirm(this.$t('payment.refundQuestion') as string).then((res: boolean) => {
          if (res) {
            this.$emit('refund', data);
          }
        });
      }
    });
  }

  public getOrders(item: Payment) {
    const orders: Order[] = this.orders.filter(
      // @ts-ignore
      (o: Order) => item.orders.map((o: Partial<Order>) => o._id).indexOf(o._id) > -1,
    );
    for (const order of orders) {
      for (const article of order.orderedArticles) {
        article.refundQuantity = article.quantity;
      }
    }

    return orders;
  }

  public onExpand(data: { item: Payment; value: boolean }) {
    if (data.value) {
      this.$emit('expand', data.item);
    }
  }
}
