export default [
  {
    text: 'payment.table.method',
    value: 'method',
    type: 'slot',
  },
  {
    text: 'payment.table.sum',
    value: 'sum',
    type: 'decimal',
  },
  {
    text: 'payment.table.codes',
    value: 'orders',
    type: 'slot',
  },
  {
    text: 'payment.table.status',
    value: 'status',
  },
  {
    text: 'payment.table.hasRefunds',
    value: 'refunds',
  },
  {
    text: 'payment.table.id',
    value: '_id',
  },
  {
    text: 'payment.table.createdAt',
    type: 'date',
    value: 'createdAt',
    format: 'DD.MM.YYYY HH:mm',
  },
  { text: '', value: 'data-table-expand' },
];
